/*
*出差单列表V2
*/
const __request = require(`./__request/__request_contentType_json`)
const consumer_evection_evectionListV2 = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/evection/evectionListV2',
        data: data
    }
    return __request(pParameter)
}
export default consumer_evection_evectionListV2