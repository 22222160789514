import EvectionSearch from "../../component/evectionSearch";
import EvectionList from "../../component/evectionList/index.js";
import WarningBox from "../../component/warningBox";

import evectionList from '@/lib/data-service/haolv-default/consumer_evection_evectionList'
import consumer_evection_evectionListV2 from '@/lib/data-service/haolv-default/consumer_evection_evectionListV2'

export default {
    data() {
        return {
            formData: {
                currentPage: 1,
                pageSize: 10,
                selectType: 1,//1.我的出差，2.我的预订,
                total: 0,
                evectionNo: '',
                evectionReason: '',
            },
            loading: false,
            params: {
                evectionStatus: '',
                evectionType: ''
            },
            detailList: []
        }
    },
    components: {
        EvectionSearch,
        EvectionList,
        WarningBox
    },
    mounted() {
        this.getList()
    },
    methods: {
        handleSizeChange(val) {
            this.formData.pageSize = val;
            this.getList();
        },
        getList() {
            this.loading = true;
            consumer_evection_evectionListV2({
              currentPage: this.formData.currentPage,
              pageSize: this.formData.pageSize,
              total: this.formData.total,
            }).then(res => {
                this.loading = false;
                this.detailList = res.datas.pageResult.pageData;

                this.formData.pageSize = res.datas.pageResult.pageSize;
                this.formData.total = res.datas.pageResult.totalCount;
            }).catch(() => {
                this.loading = false;
            });
            // evectionList(data).then(res => {
            //     this.loading = false;
            //     this.detailList = res.datas.list;
            //     this.formData.total = res.datas.totalCount;
            // }).catch(() => {
            //     this.loading = false;
            // })
        },
        handleCurrentChange(val) {
            this.formData.currentPage = val;
            this.getList();
        }
    }
}