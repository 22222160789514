//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cancelEvection from "@/lib/data-service/haolv-default/consumer_evection_cancelEvection";
import { ChangeDateFormat } from "@/lib/utils/common";
import moment from "moment"
export default {
  name: "EvectionList",
  data() {
    return {
      ChangeDateFormat: ChangeDateFormat,
      evectionLoading: false,
    };
  },
  props: {
    listType: {
      type: String
    },
    list: {
      type: Array
    }
  },
  filters: {
      formatTime(val) {
          return val ? moment(val).format("YYYY-MM-DD HH:mm:ss") : "";
      },
    },
  methods: {
    clickBook (item,ticket) {
      this.evectionLoading = true;
      console.log(ticket);

      const obj = { evectionNo: item.evectionNo, id: ticket.id };
      let jsonString = JSON.stringify(obj);

      if (ticket.businessType == 1) {
        this.$router.push({
          name: 'admin-travel-book-home-flight',
          query: {
            myEvection: jsonString,
          }
        });
      } else if (ticket.businessType == 2) {
        this.$router.push({
          name: 'admin-travel-book-home-train',
          query: {
            myEvection: jsonString,
          }
        });
      } else if (ticket.businessType == 3) {
        this.$router.push({
          name: 'admin-travel-book-home-hotel',
          query: {
            myEvection: jsonString,
          }
        });
      }
    },
    _getDays(startDate,endDate) {
      let days = moment(endDate).diff(moment(startDate), "days") + 1;
      return days;
    },
    cancelTrip(evectionNo) {
      this.$confirm("是否要取消该行程?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          cancelEvection({ evectionNo }).then(res => {
            console.log(res);
            this.$message({ type: "success", message: "取消成功！" });
            this.$emit("refresh", true);
          });
        })
        .catch(() => {});
    },
    goPay(val) {
      this.$router.push({
        name: "admin-travel-payment",
        query: {
          evectionNo: val.evectionNo
        }
      });
    },
    goInfo(evectionNo) {
      const listType = this.listType;
      let fromType = '';
      if (listType === 'evection' || listType === 'evectionDetail') {
        fromType = 5;
      } else if (listType === 'reserve') {
        fromType = 6;
      } else if (listType === 'companyPay') {
        fromType = 7;
      } else if (listType === 'pay') {
        fromType = 8;
      }
      this.$router.push({
        name: "admin-evection-detail",
        query: { evectionNo, fromType},
      });
    }
  },
  created() {},
  activated() {},
  watch: {},
  computed: {}
};
